import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchAvailableTrips, fetchAvailableTripsRequest } from '../../Redux/Actions/Actions';
import { generateToken } from './../../Redux/Actions/Actions';

function AvailableTrips(props) {

    const [items, setItems] = useState([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16]);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentItems, setCurrentItems] = useState(items && items.slice(0, pageSize));
    const [pageCount, setPageCount] = useState(Math.ceil(items && items.length / pageSize));


    const onPageChange = (index) => {
        setCurrentPage(index);
        let currentItems = items.slice(index * pageSize, pageSize * (index + 1));
        setCurrentItems(currentItems)
    }


    useEffect(() => {
        props.generateToken();
        // fetchAvailableTrips();
        onPageChange(0);
    }, [pageSize])

    // useEffect(() => {
    //     const interval = setInterval(() => props.generateToken(), 5000)
    //     return () => {
    //         clearInterval(interval);
    //     }
    // }, [])

    // const availableTrips = useSelector(state => state.availableTrips);

    return (
        <>
            {/* <div className="container">
                <table className="bg-white">
                    <thead>
                        <tr>
                            <th>
                                S.No
                            </th>
                            <th>
                                Items
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems && currentItems.length > 0 && currentItems.map((currentItem, index) => (
                            <tr key={index}>
                                <td>{currentItem.id}</td>
                                <td>{currentItem}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <nav aria-label="Page navigation example">
                    <div className="pagination pagination-sm align-items-center">
                        <button className="btn btn-outline-secondary" aria-label="First" onClick={() => onPageChange(0)} disabled={currentPage === 0}>
                            First
                        </button>
                        <button className="btn btn-outline-secondary" aria-label="Previous" onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 0}>
                            Previous
                        </button>

                        {Array(pageCount).fill(null).map((page, index) => (
                            <button className={`btn px-3 py-2 mx-1 rounded-circle ${currentPage === index ? 'btn-primary text-white' : 'btn-outline-secondary'}`} key={index} onClick={() => onPageChange(index)}>
                                {index + 1}
                            </button>
                        ))}

                        <button className="btn btn-outline-secondary" aria-label="Next" onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === pageCount - 1}>
                            Next
                        </button>

                        <button className="btn btn-outline-secondary" aria-label="Last" onClick={() => onPageChange(pageCount - 1)} disabled={currentPage === pageCount - 1}>
                            Last
                        </button>

                        <div>
                            <select className="form-select" value={pageSize} onChange={(e) => {
                                setPageSize(parseInt(e.target.value));
                                setPageCount(Math.ceil(items.length / parseInt(e.target.value)))
                            }}>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </div>
                    </div>
                </nav>
            </div> */}
            <section className="dark-mode bg-dark position-relative pt-5 pb-4">

                <div className="position-absolute top-0 start-0 w-100 h-100 bg-secondary"></div>
                <div className="container-fluid position-relative zindex-3 py-lg-4 pt-md-2 py-xl-5 mb-lg-4 px-md-4">

                    <div className="row align-items-center justify-content-center text-center pb-5 mb-2">
                        <div className="col-xl-6 pt-2">
                            <div className="d-flex align-items-center justify-content-center mb-4">
                                <h2 className="h1 mb-0">
                                    Available trips
                                </h2>
                                <div className="badge bg-white text-danger rounded-pill mb-0 p-2 px-4 px-md-3 ms-3 position-relative">
                                    <span className="ms-n2 ms-md-0"> Live </span>
                                    <span className="live-icon"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-sm-flex flex-wrap d-lg-table w-100">

                        {/* <div className="d-lg-table-row w-sm-50 w-lg-100 pe-sm-3 pe-lg-0 mb-3 mb-lg-0">
                            <div className="d-lg-table-cell align-middle w-lg-10 border-bottom py-3 py-lg-4">
                                <div className="d-flex align-items-center">
                                    <h3 className="h6 mb-0 opacity-60">
                                        Trip Number
                                    </h3>
                                </div>
                            </div>
                            <div className="d-lg-table-cell align-middle w-lg-10 border-bottom py-3 py-lg-4">
                                <div className="d-flex align-items-center">
                                    <h3 className="h6 mb-0 opacity-60">
                                        Vehicle Type
                                    </h3>
                                </div>
                            </div>
                            <div className="d-lg-table-cell align-middle w-lg-10 border-bottom py-3 py-lg-4">
                                <div className="d-flex align-items-center">
                                    <h3 className="h6 mb-0 opacity-60">
                                        Category Type
                                    </h3>
                                </div>
                            </div>
                            <div className="d-lg-table-cell align-middle w-lg-10 border-bottom py-3 py-lg-4">
                                <div className="d-flex align-items-center">
                                    <h3 className="h6 mb-0 opacity-60">
                                        Weight
                                    </h3>
                                </div>
                            </div>
                            <div className="d-lg-table-cell align-middle w-lg-10 border-bottom py-3 py-lg-4">
                                <div className="d-flex align-items-center">
                                    <h3 className="h6 mb-0 opacity-60">
                                        No. of parcels
                                    </h3>
                                </div>
                            </div>
                            <div className="d-lg-table-cell align-middle w-lg-10 border-bottom py-3 py-lg-4">
                                <div className="d-flex align-items-center">
                                    <h3 className="h6 mb-0 opacity-60">
                                        No. of stops
                                    </h3>
                                </div>
                            </div>
                            <div className="d-lg-table-cell align-middle w-lg-10 border-bottom py-3 py-lg-4">
                                <div className="d-flex align-items-center">
                                    <h3 className="h6 mb-0 opacity-60">
                                        Distance
                                    </h3>
                                </div>
                            </div>
                            <div className="d-lg-table-cell align-middle w-lg-10 border-bottom py-3 py-lg-4">
                                <div className="d-flex align-items-center">
                                    <h3 className="h6 mb-0 opacity-60">
                                        City from
                                    </h3>
                                </div>
                            </div>
                            <div className="d-lg-table-cell align-middle w-lg-10 border-bottom py-3 py-lg-4">
                                <div className="d-flex align-items-center">
                                    <h3 className="h6 mb-0 opacity-60">
                                        City to
                                    </h3>
                                </div>
                            </div>
                            <div className="d-lg-table-cell align-middle w-lg-10 border-bottom py-3 py-lg-4">
                                <div className="d-flex align-items-center">
                                    <h3 className="h6 mb-0 opacity-60">
                                        Duration
                                    </h3>
                                </div>
                            </div>
                            <div className="d-lg-table-cell align-middle w-lg-10 border-bottom py-3 py-lg-4">
                                <div className="d-flex align-items-center">
                                    <h3 className="h6 mb-0 opacity-60">
                                        Earnings
                                    </h3>
                                </div>
                            </div>
                            <div className="d-lg-table-cell align-middle w-lg-10 border-bottom py-3 py-lg-4">
                                <div className="d-flex align-items-center">
                                    <h3 className="h6 mb-0 opacity-60">
                                        Action
                                    </h3>
                                </div>
                            </div>
                        </div>

                        {props.isLoading ?
                            <div className="spinner-border text-success my-3" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                            :
                            <div>
                                {props.availableTrips.length === 0 &&
                                    <div className="d-lg-table-row pe-sm-3 pe-lg-0 mb-3 mb-lg-0">
                                        <h3 className="h5 py-3 mb-0 text-danger">
                                            Trips are not available right now
                                        </h3>
                                    </div>
                                }
                            </div>
                        }

                        {props.availableTrips.length > 0 && props.availableTrips.map((availableTrip, index) => {
                            return (
                                <div className="d-lg-table-row w-sm-50 w-lg-100 pe-sm-3 pe-lg-0 mb-3 mb-lg-0">
                                    <div className="d-lg-table-cell align-middle w-lg-10 border-bottom py-3 py-lg-4">
                                        <div className="d-flex align-items-center">
                                            <h3 className="h6 mb-0">
                                                {availableTrip.shipments[0].orderId}
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="d-lg-table-cell align-middle w-lg-10 h6 text-white border-bottom py-3 py-lg-4">
                                        {availableTrip.transportTypes[0]}
                                    </div>
                                    <div className="d-lg-table-cell align-middle w-lg-10 h6 text-white border-bottom py-3 py-lg-4">
                                        {availableTrip.productType}
                                    </div>
                                    <div className="d-lg-table-cell align-middle w-lg-10 h6 text-white border-bottom py-3 py-lg-4">
                                        {availableTrip.steps[0].totalWeight + ` KG`}
                                    </div>
                                    <div className="d-lg-table-cell align-middle w-lg-10 h6 text-white border-bottom py-3 py-lg-4">
                                        {availableTrip.steps[0].numberOfParcels}
                                    </div>
                                    <div className="d-lg-table-cell align-middle w-lg-10 h6 text-white border-bottom py-3 py-lg-4">
                                        {availableTrip.steps.length}
                                    </div>
                                    <div className="d-lg-table-cell align-middle w-lg-10 h6 text-white border-bottom py-3 py-lg-4">
                                        {availableTrip.distance / 1000} KM
                                    </div>
                                    <div className="d-lg-table-cell align-middle w-lg-15 h6 text-white border-bottom py-3 py-lg-4">
                                        {availableTrip.steps[0].destination.address}
                                    </div>
                                    <div className="d-lg-table-cell align-middle w-lg-15 h6 text-white border-bottom py-3 py-lg-4">
                                        {availableTrip.steps[availableTrip.steps.length - 1].destination.address}
                                    </div>
                                    <div className="d-lg-table-cell align-middle w-lg-10 h6 text-white border-bottom py-3 py-lg-4">
                                        {Math.floor(availableTrip.drivingDuration / 60) + ` Minutes`}
                                    </div>
                                    <div className="d-lg-table-cell align-middle w-lg-10 h6 text-success border-bottom py-3 py-lg-4">
                                        {availableTrip.driverEarnings + ` SAR`}
                                    </div>
                                    <div className="d-lg-table-cell align-middle w-lg-10 border-bottom-lg pt-2 pb-3 py-lg-4">
                                        <button type="button" className="btn btn-sm btn-outline-secondary fs-sm w-100 w-lg-auto">
                                            Know more
                                        </button>
                                    </div>
                                </div>
                            )
                        })} */}

                        <div className="table-responsive">
                            <table className="table mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col" className="small">
                                            Trip Number
                                        </th>
                                        <th scope="col" className="small">
                                            Vehicle Type
                                        </th>
                                        <th scope="col" className="small">
                                            Category Type
                                        </th>
                                        <th scope="col" className="small">
                                            Weight
                                        </th>
                                        <th scope="col" className="small">
                                            No.of parcels
                                        </th>
                                        <th scope="col" className="small">
                                            No.of stops
                                        </th>
                                        <th scope="col" className="small">
                                            Distance
                                        </th>
                                        <th scope="col" className="small w-md-15 mw-md-15">
                                            City from
                                        </th>
                                        <th scope="col" className="small w-md-15 mw-md-15">
                                            City to
                                        </th>
                                        <th scope="col" className="small">
                                            Duration
                                        </th>
                                        <th scope="col" className="small">
                                            Earnings
                                        </th>
                                        <th scope="col" className="small">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="table-group-divider">
                                    {props.availableTrips.length > 0 && props.availableTrips.map((availableTrip, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="fw-medium text-white">
                                                    {availableTrip.id}
                                                </td>
                                                <td className="fw-medium text-white">
                                                    {availableTrip.transportTypes[0]}
                                                </td>
                                                <td className="fw-medium text-white">
                                                    {availableTrip.productType}
                                                </td>
                                                <td className="fw-medium text-white">
                                                    {availableTrip.steps[0].totalWeight + ` KG`}
                                                </td>
                                                <td className="fw-medium text-white">
                                                    {availableTrip.steps[0].numberOfParcels}
                                                </td>
                                                <td className="fw-medium text-white">
                                                    {availableTrip.steps.length}
                                                </td>
                                                <td className="fw-medium text-white">
                                                    {availableTrip.distance / 1000 + ` KM`}
                                                </td>
                                                <td className="fw-medium text-white">
                                                    {availableTrip.steps[0].destination.address}
                                                </td>
                                                <td className="fw-medium text-white">
                                                    {availableTrip.steps[availableTrip.steps.length - 1].destination.address}
                                                </td>
                                                <td className="fw-medium text-white">
                                                    {Math.floor(availableTrip.drivingDuration / 60) + ` Minutes`}
                                                </td>
                                                <td className="fw-medium text-success">
                                                    {availableTrip.driverEarnings + ` SAR`}
                                                </td>
                                                <td className="fw-medium text-white">
                                                    <button type="button" className="btn btn-sm btn-outline-secondary fs-sm w-100 w-lg-auto">
                                                        <a className="text-primary text-decoration-none" href="https://play.google.com/store/apps/details?id=com.cargoarabia.courier" target="_blank" rel="noreferrer">Let's drive </a>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>

                            {props.isLoading ?
                                <div className="spinner-border text-success my-3" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                :
                                <>
                                    {props.availableTrips.length === 0 &&
                                        <div className="d-flex align-items-center justify-content-center" style={{ height: "200px" }}>
                                            <p className="h5 text-danger mb-0">
                                                No trips are available
                                            </p>
                                        </div>
                                    }
                                </>
                            }
                        </div>

                    </div>
                </div>

                <div className="position-absolute end-0 bottom-0 text-secondary">
                    <svg width="469" height="343" viewBox="0 0 469 343" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.08" fillRule="evenodd" clipRule="evenodd" d="M273.631 680.872C442.436 768.853 639.315 708.216 717.593 558.212C795.871 408.208 732.941 212.157 564.137 124.176C395.333 36.195 198.453 96.8326 120.175 246.836C41.8972 396.84 104.827 592.891 273.631 680.872ZM236.335 752.344C440.804 858.914 688.289 788.686 789.109 595.486C889.928 402.286 805.903 159.274 601.433 52.7043C396.964 -53.8654 149.479 16.3623 48.6595 209.562C-52.1598 402.762 31.8652 645.774 236.335 752.344Z" fill="currentColor"></path><path opacity="0.08" fillRule="evenodd" clipRule="evenodd" d="M298.401 633.404C434.98 704.59 598.31 656.971 664.332 530.451C730.355 403.932 675.946 242.827 539.367 171.642C402.787 100.457 239.458 148.076 173.435 274.595C107.413 401.114 161.822 562.219 298.401 633.404ZM288.455 652.464C434.545 728.606 611.369 678.429 683.403 540.391C755.437 402.353 695.402 228.725 549.312 152.583C403.222 76.4404 226.398 126.617 154.365 264.655C82.331 402.693 142.365 576.321 288.455 652.464Z" fill="currentColor"></path></svg>
                </div>

            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        availableTrips: state.availableTrips.availableTrips,
        isLoading: state.availableTrips.isLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchAvailableTrips,
        generateToken
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AvailableTrips);