import React from 'react'

export default function Locations() {
    return (
        <div>
            <section className="dark-mode bg-dark bg-size-cover bg-repeat-0 bg-position-center position-relative overflow-hidden py-5 mb-4 vh-md-100 d-flex align-items-center" style={{ backgroundImage: "url(img/home/hero-bg.png)" }}>
                <div className="container position-relative zindex-2 pt-5 pb-md-2 pb-lg-4 pb-xl-5">
                    <div className="row pt-3 pb-2 py-md-4 pt-md-5 mt-md-5 align-items-center">

                        <div className="col-xl-5 col-md-6 pt-lg-5 text-center text-md-start mb-4 mb-md-0">
                            <h1 className="display-4 pb-2 pb-sm-3">
                                Locations we deliver
                            </h1>
                            <p className="fs-lg d-md-none d-xl-block pb-2 pb-md-0 mb-4 mb-md-5">
                                Cities in Saudi Arabia where Courier CargoArabia delivers
                            </p>
                        </div>

                        <div className="col-xl-7 col-md-6 d-md-flex justify-content-center align-items-center">
                            <div className="mx-auto ms-md-0 me-md-n5">
                                <div className="zindex-2" data-depth="0.1">
                                    <img src="img/locations/locationIllustration.svg" style={{ width: "500px" }} alt="Illustration" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <section className="container p-5 my-2 my-lg-4 my-xl-5 card border-0 shadow">
                    <div className="row">
                        <div className="col-6 col-md-4 col-xl-3 order-md-1">
                            <h3 className="mb-4">
                                Mecca Region
                            </h3>
                            <ul className="list-unstyled mb-5">
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Makkah
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Makkah
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Makkah
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Makkah
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Makkah
                                </li>
                            </ul>
                        </div>
                        <div className="col-6 col-md-4 col-xl-3 order-md-1">
                            <h3 className="mb-4">
                                Riyadh Region
                            </h3>
                            <ul className="list-unstyled mb-5">
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Riyadh
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Riyadh
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Riyadh
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Riyadh
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Riyadh
                                </li>
                            </ul>
                        </div>
                        <div className="col-6 col-md-4 col-xl-3 order-md-1">
                            <h3 className="mb-4">
                                Eastern Region
                            </h3>
                            <ul className="list-unstyled mb-5">
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Dammam
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Dammam
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Dammam
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Dammam
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Dammam
                                </li>
                            </ul>
                        </div>
                        <div className="col-6 col-md-4 col-xl-3 order-md-1">
                            <h3 className="mb-4">
                                Asir Region
                            </h3>
                            <ul className="list-unstyled mb-5">
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Abha
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Abha
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Abha
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Abha
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Abha
                                </li>
                            </ul>
                        </div>
                        <div className="col-6 col-md-4 col-xl-3 order-md-1">
                            <h3 className="mb-4">
                                Mecca Region
                            </h3>
                            <ul className="list-unstyled">
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Makkah
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Makkah
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Makkah
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Makkah
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Makkah
                                </li>
                            </ul>
                        </div>
                        <div className="col-6 col-md-4 col-xl-3 order-md-1">
                            <h3 className="mb-4">
                                Riyadh Region
                            </h3>
                            <ul className="list-unstyled">
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Riyadh
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Riyadh
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Riyadh
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Riyadh
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Riyadh
                                </li>
                            </ul>
                        </div>
                        <div className="col-6 col-md-4 col-xl-3 order-md-1">
                            <h3 className="mb-4">
                                Eastern Region
                            </h3>
                            <ul className="list-unstyled">
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Dammam
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Dammam
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Dammam
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Dammam
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Dammam
                                </li>
                            </ul>
                        </div>
                        <div className="col-6 col-md-4 col-xl-3 order-md-1">
                            <h3 className="mb-4">
                                Asir Region
                            </h3>
                            <ul className="list-unstyled">
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Abha
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Abha
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Abha
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Abha
                                </li>
                                <li className="d-flex align-items-center mb-2">
                                    <i className="bx bx-check-circle text-primary fs-xl me-2"></i>
                                    Abha
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </section>
        </div>
    )
}
