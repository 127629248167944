import React from 'react'
import { Link } from 'react-router-dom';

import './ErrorPage.css';

export default function ErrorPage() {
    return (
        <section style={{ backgroundImage: 'url(/img/Footer/footer.jpg)' }}>
            {/* <div className="container my-5 pt-4 py-lg-5 text-md-start text-center">
                <div className="row align-items-center">
                    <div className="col-xl-6 col-md-7 order-md-2 ms-n5">
                        <img src="/img/404/ErrorIllustration.svg" className="rellax d-block mx-auto error-image" alt="Error" />
                    </div>

                    <div className="col-md-5 offset-xl-1 order-md-1">
                        <h1 className="display-1 mb-sm-4 mt-n4 mt-sm-n5">Error 404</h1>
                        <p className="mb-md-5 mb-4 mx-md-0 mx-auto pb-2 lead">
                            The page you are looking for is not available.
                        </p>
                        <Link to="homepage" className="btn btn-lg btn-primary shadow-primary w-sm-auto w-100">
                            <i className="bx bx-home-alt me-2 ms-n1 lead"></i>
                            Go to homepage
                        </Link>
                    </div>
                </div>
            </div> */}
            <section className="container my-5 pt-4 py-lg-5">
                <div className="d-flex align-items-center justify-content-center zindex-5">
                    <div className="text-center">
                        <img src="/img/404/ErrorIllustration.svg" className="rellax d-block mx-auto error-image" alt="Error" />
                        <p className="fs-xl pb-3 pb-md-0 my-md-5">
                            The page you are looking for is not available.
                        </p>
                        <Link to="homepage" className="btn btn-lg btn-primary shadow-primary w-sm-auto w-100">
                            <i className="bx bx-home-alt me-2 ms-n1 lead"></i>
                            Go to homepage
                        </Link>
                    </div>
                </div>
            </section>
        </section>
    )
}
