import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [pathname]);

    return null;
}

export const ScrollToTopButton = () => {
    const [visible, setVisible] = useState();

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 200) {
            setVisible(true)
        }
        else if (scrolled <= 200) {
            setVisible(false)
        }
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }

    window.addEventListener('scroll', toggleVisible);

    return (
        <button onClick={scrollToTop}
            className="btn-primary btn-scroll-top bg-primary border-0 shadow show"
            data-scroll=""
            style={{
                zIndex: "100",
                cursor: "pointer",
                display: visible ? '' : 'none'
            }}
        >
            <i className="bx bx-up-arrow-alt fs-xl"></i>
        </button>
    )

}