import React from 'react'
import { Link } from 'react-router-dom';
import AvailableTrips from '../AvailableTrips/AvailableTrips';

function Body() {

    return (
        <div>
            <section className="dark-mode bg-dark bg-size-cover bg-repeat-0 bg-position-center position-relative overflow-hidden py-5 mb-4 vh-md-100 d-flex align-items-center" style={{ backgroundImage: "url(img/home/hero-bg.png)" }}>
                <div className="container position-relative zindex-2 pt-5 pb-md-2 pb-lg-4 pb-xl-5">
                    <div className="row pt-3 pb-2 py-md-4 pt-md-5 mt-md-5 align-items-center">

                        <div className="col-xl-5 col-md-6 pt-lg-5 text-center text-md-start mb-4 mb-md-0 order-1 order-md-0">
                            <h1 className="display-3 pb-2 pb-sm-3">Adjust your work, not life.</h1>
                            <p className="fs-lg d-md-none d-xl-block pb-2 pb-md-0 mb-4 mb-md-5">No matter what drives you, Courier CargoArabia helps you get there.</p>
                            {/* <div className="d-flex justify-content-center justify-content-md-start pb-2 pt-lg-2 pt-xl-0">
                                <a href="home" className="btn btn-lg btn-primary me-3 me-sm-4">Start trial now</a>
                                <a href="home" className="btn btn-lg btn-outline-secondary">Contact us</a>
                            </div> */}
                            <div className="d-flex align-items-center justify-content-center justify-content-md-start text-start">
                                <div className="d-flex me-3">
                                    <div className="d-flex align-items-center justify-content-center bg-light rounded-circle" style={{ width: "40px", height: "40px" }}>
                                        <img src="img/avatar/1.jpg" className="rounded-circle" width="48" alt="Avatar" />
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center bg-light rounded-circle ms-n3" style={{ width: "40px", height: "40px" }}>
                                        <img src="img/avatar/2.jpg" className="rounded-circle" width="48" alt="Avatar" />
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center bg-light rounded-circle ms-n3" style={{ width: "40px", height: "40px" }}>
                                        <img src="img/avatar/3.jpg" className="rounded-circle" width="48" alt="Avatar" />
                                    </div>
                                </div>
                                <div className="text-light"><strong>10k+</strong> drivers already with us</div>
                            </div>
                        </div>

                        <div className="col-xl-7 col-md-6 d-md-flex justify-content-center align-items-center order-0 order-md-1">
                            <div className="mx-auto ms-md-0 me-md-n5">
                                <div className="zindex-2 mb-5 mb-md-0" data-depth="0.1">
                                    <img src="img/home/Illustration.svg" style={{ width: "500px" }} alt="Illustration" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container py-5 my-5">
                <div className="text-center pb-4 pb-md-0 mb-2 mb-md-5 mx-auto">
                    <h2 className="h1">
                        What is Courier CargoArabia
                    </h2>
                    <p className="mb-0 w-md-50 mx-auto">
                        It’s simple: You use your vehicle to deliver packages for CargoArabia as a way of earning extra money to move you closer to your goals.
                    </p>
                </div>

                <div className="steps steps-sm steps-horizontal-md steps-center mb-4">
                    <div className="step">
                        <div className="step-number">
                            <div className="step-number-inner">1</div>
                        </div>
                        <div className="step-body">
                            <h3 className="h4 mb-3">Registration & Verification </h3>
                        </div>
                    </div>
                    <div className="step">
                        <div className="step-number">
                            <div className="step-number-inner">2</div>
                        </div>
                        <div className="step-body">
                            <h3 className="h4 mb-3">Find trips in your location</h3>
                        </div>
                    </div>
                    <div className="step">
                        <div className="step-number">
                            <div className="step-number-inner">3</div>
                        </div>
                        <div className="step-body">
                            <h3 className="h4 mb-3">Make deliveries from our app</h3>
                        </div>
                    </div>
                    <div className="step">
                        <div className="step-number">
                            <div className="step-number-inner">4</div>
                        </div>
                        <div className="step-body">
                            <h3 className="h4 mb-3">Get paid, enjoy life and repeat.</h3>
                        </div>
                    </div>
                </div>

                {/* <div className="bg-secondary position-relative rounded-3 overflow-hidden px-4 px-sm-5">
                    <div className="position-absolute top-50 start-50 w-75 h-75 translate-middle d-flex align-items-center justify-content-center zindex-5">
                        <a href="home" className="btn btn-video btn-icon btn-xl bg-white stretched-link" data-bs-toggle="video">
                            <i className="bx bx-play"></i>
                        </a>
                    </div>
                    <div className="pt-4 mt-sm-3 px-3 px-sm-5 mx-md-5">
                        <img src="home" width="786" className="rellax d-block mx-auto mt-lg-4" alt="Process video" />
                    </div>
                </div> */}
            </section>

            <section className="container my-5 pt-5 pb-2 pb-md-3 pb-lg-5 bg-gray-200 rounded-5">
                <div className="row align-items-center">
                    <div className="col-md-6 col-xl-5 offset-lg-1 offset-xl-2 order-md-2 mb-2 mb-md-0 pb-4 pb-md-0">
                        <div className="position-relative mx-auto mx-md-0">
                            <img src="/img/home/Illustration1.svg" className="rellax d-block" alt="Goals" />
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-5 col-xl-4 offset-xl-1 order-md-1 pb-md-4">
                        <div className="p-3 p-md-0">
                            <h2 className="pb-3">
                                Make quicker progress toward your goals.
                            </h2>
                            <p>
                                Everybody’s driven by something. Maybe you’re saving up for something big. Or maybe you just want to earn some extra money for day-to-day expenses. Or maybe you’re focusing on paying down your student loans. Whatever drives you, get closer to your goals with Courier CargoArabia.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mt-2 mt-md-0 pt-5 pt-md-4 pt-lg-0 pb-md-3 pb-lg-5">
                    <div className="col-md-6 col-lg-4 offset-xl-1 mb-2 mb-md-0 pb-4 pb-md-0">
                        <div className="position-relative mx-auto mx-md-0">
                            <img src="/img/home/Illustration2.svg" className="rellax d-block" alt="Work" />
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-4 offset-lg-2">
                        <div className="p-3 p-md-0">
                            <h2 className="pb-3 w-lg-75">
                                Adjust your work, not your life.
                            </h2>
                            <p>
                                We know how valuable your time is. With Courier CargoArabia, you work only when you want to. You can plan your week by reserving blocks in advance or picking them each day based on your availability. Choose the blocks that fit your schedule, then get back to living your life.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="pt-5 d-none d-dark-mode-block"></div>
            </section>

            <div id="available-trips">
                <AvailableTrips />
            </div>

            <section className="container py-5 mb-lg-2">
                <div className="row py-2 py-md-4 py-lg-5">
                    <div className="col-xl-4 col-md-5 text-center text-md-start pt-md-2 pb-2 pb-md-0 mb-4 mb-md-0">
                        <h2 className="pb-3 mb-1 mb-lg-3">Any questions? <br className="d-none d-md-inline" />Check out the FAQs</h2>
                        <p className="fs-lg pb-3 mb-2 mb-lg-3">Still have unanswered questions and need to get in touch?</p>
                        <div className="row row-cols-1 row-cols-sm-2 g-3 g-sm-4">
                            <div className="col">
                                <div className="card card-hover">
                                    <div className="card-body pb-3">
                                        <i className="bx bxs-phone-call d-block fs-2 text-primary mb-2 py-1"></i>
                                        <p className="fs-sm mb-2">Still have  questions?</p>
                                        <a href="callto:+966" className="btn btn-link stretched-link px-0">
                                            Call us
                                            <i className="bx bx-right-arrow-alt fs-xl ms-2"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card card-hover">
                                    <div className="card-body pb-3">
                                        <i className="bx bx-message-rounded-dots d-block fs-2 text-success mb-2 py-1"></i>
                                        <p className="fs-sm mb-2">
                                            Still, not clarified?
                                        </p>
                                        <Link to="faqs" className="btn btn-link text-success stretched-link px-0">
                                            Click here
                                            <i className="bx bx-right-arrow-alt fs-xl ms-2"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7 offset-xl-1">
                        <div className="accordion" id="faq">
                            <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h2 className="accordion-header" id="q1-heading">
                                    <button className="accordion-button shadow-none rounded-3" type="button" data-bs-toggle="collapse" data-bs-target="#q1" aria-expanded="true" aria-controls="q1">
                                        What is Courier CargoArabia?
                                    </button>
                                </h2>
                                <div id="q1" className="accordion-collapse collapse show" aria-labelledby="q1-heading" data-bs-parent="#faq">
                                    <div className="accordion-body fs-sm pt-0">
                                        <p>
                                            Courier CargoArabia is a program where independent contractors, called delivery partners, deliver CargoArabia orders. Hundreds of millions of items are available through CargoArabia, including electronics, household essentials, and much more. Delivery partners use their own vehicles to deliver packages to CargoArabia customers. It provides flexible opportunity for Delivery Partners looking to turn free time into supplemental or part-time income.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h2 className="accordion-header" id="q2-heading">
                                    <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q2" aria-expanded="false" aria-controls="q2">
                                        What kind of deliveries will I make?
                                    </button>
                                </h2>
                                <div id="q2" className="accordion-collapse collapse" aria-labelledby="q2-heading" data-bs-parent="#faq">
                                    <div className="accordion-body fs-sm pt-0">
                                        <p>
                                            <strong>CargoArabia.com:</strong> Pick up products, food, electronic , apparels or anything else that can be ordered on CargoArabia.com or its allied apps and sites from an CargoArabia delivery station and deliver directly to customers.

                                        </p>
                                        <p>
                                            <strong> Prime Now:</strong> Pick up groceries or household items from an CargoArabia delivery station and deliver directly to customers.
                                        </p>
                                        <p className="mb-0">
                                            <strong>Store Orders:</strong> Pick up orders from local stores and deliver directly to customers.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h2 className="accordion-header" id="q3-heading">
                                    <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q3" aria-expanded="false" aria-controls="q3">
                                        Can I rely on Courier CargoArabia as a source of full-time income?
                                    </button>
                                </h2>
                                <div id="q3" className="accordion-collapse collapse" aria-labelledby="q3-heading" data-bs-parent="#faq">
                                    <div className="accordion-body fs-sm pt-0">
                                        <p>
                                            Courier CargoArabia provides a flexible opportunity for Delivery Partners looking to turn free time into supplemental or part-time income. Delivery blocks are not guaranteed and are subject to availability.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h2 className="accordion-header" id="q4-heading">
                                    <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q4" aria-expanded="false" aria-controls="q4">
                                        How quickly can I get started?
                                    </button>
                                </h2>
                                <div id="q4" className="accordion-collapse collapse" aria-labelledby="q4-heading" data-bs-parent="#faq">
                                    <div className="accordion-body fs-sm pt-0">
                                        <p>
                                            You can start as soon as you fill out some basic information and your background check is successfully completed. This process usually takes 5-10 business days.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h2 className="accordion-header" id="q5-heading">
                                    <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q5" aria-expanded="false" aria-controls="q5">
                                        What is CargoArabia's policy on discrimination?
                                    </button>
                                </h2>
                                <div id="q5" className="accordion-collapse collapse" aria-labelledby="q5-heading" data-bs-parent="#faq">
                                    <div className="accordion-body fs-sm pt-0">
                                        <p>
                                            CargoArabia is focused on giving opportunities to under represented cohorts based on policy and it has zero tolerance towards discrimination of any kind.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Body;