import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Homepage from '../../Homepage/Homepage'
import WhoWeAre from '../../WhoWeAre/WhoWeAre';
import Locations from './../../Locations/Locations';
import FAQs from './../../FAQs/FAQs';
import SiteTerms from './../../SiteTerms/SiteTerms';
import PrivacyNotice from './../../PrivacyNotice/PrivacyNotice';
import ErrorPage from '../../ErrorPage/ErrorPage';
import AvailableTrips from './../../AvailableTrips/AvailableTrips';

export default function Routing() {
    return (
        <Routes>
            <Route path="homepage" element={<Homepage />} />
            <Route path="available-trips" element={<AvailableTrips />} />
            <Route path="who-we-are" element={<WhoWeAre />} />
            <Route path="locations" element={<Locations />} />
            <Route path="faqs" element={<FAQs />} />
            <Route path="site-terms" element={<SiteTerms />} />
            <Route path="privacy-notice" element={<PrivacyNotice />} />

            <Route path="/" element={<Homepage />} />
            <Route path="*" element={<ErrorPage />} />
        </Routes>
    )
}
