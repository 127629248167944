import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import './Header.css';

export default function Header() {
    return (
        <div>
            <header className="header navbar navbar-expand-lg position-absolute navbar-fixed-top">
                <div className="container px-3 py-1 bg-white shadow-lg rounded-pill mt-1">
                    <Link to="homepage" className="navbar-brand ps-2 ps-md-3">
                        <img src="Logo.png" width="100" alt="Logo" />
                    </Link>
                    <div id="navbarNav" className="offcanvas offcanvas-start">
                        <div className="offcanvas-header border-bottom">
                            <h5 className="offcanvas-title">Menu</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <ul className="navbar-nav float-md-end me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <NavLink to="homepage" className="nav-link" activeclassname="active">Home</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="who-we-are" className="nav-link">Who we are</NavLink>
                                </li>
                                {/* <li className="nav-item">
                                    <NavLink to="available-trips" className="nav-link">Available trips</NavLink>
                                </li> */}
                                <li className="nav-item">
                                    <NavLink to="locations" className="nav-link">Locations</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="faqs" className="nav-link">FAQ's</NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="offcanvas-header border-top">
                            <a href="https://play.google.com/store/apps/details?id=com.cargoarabia.courier" className="btn btn-primary w-100" target="_blank" rel="noreferrer">
                                {/* <i className="bx bx-download fs-5 lh-1 me-1"></i> */}
                                Let's drive
                            </a>
                        </div>
                    </div>
                    {/* <div className="form-check form-switch mode-switch pe-lg-1 ms-auto me-4" data-bs-toggle="mode">
                        <input type="checkbox" className="form-check-input" id="theme-mode" />
                        <label className="form-check-label d-none d-sm-block" for="theme-mode">Light</label>
                        <label className="form-check-label d-none d-sm-block" for="theme-mode">Dark</label>
                    </div> */}
                    <button type="button" className="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <a href="https://play.google.com/store/apps/details?id=com.cargoarabia.courier" className="btn btn-primary btn-sm fs-sm rounded-pill d-none d-lg-inline-flex text-capitalize ms-md-4" target="_blank" rel="noreferrer">
                        {/* <i className="bx bx-download fs-5 lh-1 me-1"></i> */}
                        Let's drive
                    </a>
                </div>
            </header>
        </div>
    )
}
