import { FETCH_AVAILABLE_TRIPS_REQUEST, FETCH_AVAILABLE_TRIPS_SUCCESS, FETCH_AVAILABLE_TRIPS_FAIL } from './../Actions/ActionTypes';

const initialState = {
    availableTrips: [],
    error: '',
    isLoading: true
}

export const tripsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_AVAILABLE_TRIPS_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case FETCH_AVAILABLE_TRIPS_SUCCESS:
            return {
                // ...state,
                isLoading: false,
                availableTrips: action.data,
                error: '',
            }
        case FETCH_AVAILABLE_TRIPS_FAIL:
            return {
                isLoading: false,
                availableTrips: [],
                error: action.data
            }
        default:
            return state;
    }
}