import React from 'react'

export default function FAQs() {
    return (
        <div>
            <section className="dark-mode bg-dark bg-size-cover bg-repeat-0 bg-position-center position-relative overflow-hidden py-5 mb-4 vh-md-100 d-flex align-items-center" style={{ backgroundImage: "url(img/home/hero-bg.png)" }}>
                <div className="container position-relative zindex-2 pt-5 pb-md-2 pb-lg-4 pb-xl-5">
                    <div className="row pt-3 pb-2 py-md-4 pt-md-5 mt-md-5 align-items-center">

                        <div className="col-xl-5 col-md-6 pt-lg-5 text-center text-md-start mb-4 mb-md-0">
                            <h1 className="display-4 pb-2 pb-sm-3">
                                Not finding
                                what you need?
                            </h1>
                            <p className="fs-lg d-md-none d-xl-block pb-2 pb-md-0 mb-4 mb-md-5">
                                We're here to help you.
                            </p>
                        </div>

                        <div className="col-xl-7 col-md-6 d-md-flex justify-content-center align-items-center">
                            <div className="mx-auto ms-md-0 me-md-n5">
                                <div className="zindex-2" data-depth="0.1">
                                    <img src="img/faqs/faqIllustration.svg" style={{ width: "500px" }} alt="Illustration" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container pt-5 pb-2 mt-3 mt-sm-4 mt-xl-5">
                <h2 className="h1 text-center pb-3 pb-lg-5">
                    FAQ's about Courier CargoArabia
                </h2>

                <ul className="nav nav-tabs flex-nowrap justify-content-lg-center overflow-auto pb-2 mb-3 mb-lg-4" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link text-nowrap active" id="aboutCargoArabia-tab" data-bs-toggle="tab" data-bs-target="#aboutCargoArabia" type="button" role="tab" aria-controls="aboutCargoArabia" aria-selected="true" tabindex="-1">
                            About Courier CargoArabia
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link text-nowrap" id="gettingStarted-tab" data-bs-toggle="tab" data-bs-target="#gettingStarted" type="button" role="tab" aria-controls="gettingStarted" aria-selected="true" tabindex="-1">
                            Getting Started
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link text-nowrap" id="appDownload-tab" data-bs-toggle="tab" data-bs-target="#appDownload" type="button" role="tab" aria-controls="appDownload" aria-selected="true" tabindex="-1">
                            App Download
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link text-nowrap" id="accountSetup-tab" data-bs-toggle="tab" data-bs-target="#accountSetup" type="button" role="tab" aria-controls="accountSetup" aria-selected="true">
                            Account Setup
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link text-nowrap" id="earnings-tab" data-bs-toggle="tab" data-bs-target="#earnings" type="button" role="tab" aria-controls="earnings" aria-selected="true">
                            Earnings
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link text-nowrap" id="standings-tab" data-bs-toggle="tab" data-bs-target="#standings" type="button" role="tab" aria-controls="standings" aria-selected="true">
                            Standings
                        </button>
                    </li>
                </ul>

                <div className="tab-content bg-secondary rounded-3 p-4 w-lg-75 mx-auto">

                    <div className="tab-pane fade active show" id="aboutCargoArabia" role="tabpanel" aria-labelledby="aboutCargoArabia-tab">
                        <div className="accordion" id="aboutCargoArabiaAccordion">
                            <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h3 className="accordion-header">
                                    <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-1" aria-expanded="false" aria-controls="q-1">What if I don't have any professional background?</button>
                                </h3>
                                <div className="accordion-collapse collapse" id="q-1" data-bs-parent="#aboutCargoArabiaAccordion">
                                    <div className="accordion-body fs-sm pt-0">
                                        <p>Nunc duis id aenean gravida tincidunt eu, tempor ullamcorper. Viverra aliquam arcu, viverra et, cursus. Aliquet pretium cursus adipiscing gravida et consequat lobortis arcu velit. Nibh pharetra fermentum duis accumsan lectus non. Massa cursus molestie lorem scelerisque pellentesque. Nisi, enim, arcu purus gravida adipiscing euismod montes, duis egestas. Vehicula eu etiam quam tristique tincidunt suspendisse ut consequat.</p>
                                        <p>Ornare senectus fusce dignissim ut. Integer consequat in eu tortor, faucibus et lacinia posuere. Turpis sit viverra lorem suspendisse lacus aliquam auctor vulputate. Quis egestas aliquam nunc purus lacus, elit leo elit facilisi. Dignissim amet adipiscing massa integer.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h3 className="accordion-header">
                                    <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-2" aria-expanded="false" aria-controls="q-2">How is this different from other courses on the market?</button>
                                </h3>
                                <div className="accordion-collapse collapse" id="q-2" data-bs-parent="#aboutCargoArabiaAccordion">
                                    <div className="accordion-body fs-sm pt-0">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce in facilisis nibh. Vestibulum ac porttitor sapien. Curabitur laoreet malesuada gravida. Phasellus vehicula vestibulum consequat. Curabitur feugiat eget sem vitae laoreet. Fusce porttitor finibus tellus, quis condimentum nibh. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vivamus vehicula malesuada magna at viverra. Fusce non est eget libero convallis fringilla suspendisse.</p>
                                        <p>Nunc dolor velit, interdum finibus bibendum vel, mattis a magna. Mauris mollis sapien ac mi aliquet varius. Proin nec est nibh. Dignissim amet adipiscing massa integer.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h3 className="accordion-header">
                                    <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-3" aria-expanded="false" aria-controls="q-3">How much time does it take to do my homework per week? What if I don't like it?</button>
                                </h3>
                                <div className="accordion-collapse collapse" id="q-3" data-bs-parent="#aboutCargoArabiaAccordion">
                                    <div className="accordion-body fs-sm pt-0">
                                        <p>Suspendisse viverra volutpat eros. Curabitur in scelerisque lacus, quis fringilla sem. Nunc rutrum vel magna et ullamcorper. Sed consectetur augue vitae ligula consectetur, eu dapibus justo molestie. Phasellus sit amet metus magna. Sed tincidunt tempus felis vitae commodo. Etiam lobortis justo in elit pretium, sit amet aliquet tellus euismod. Curabitur in purus sed turpis aliquet pretium. Nunc ut magna tempus, iaculis sem id, vulputate ipsum. Etiam fermentum malesuada quam, in tempus purus pulvinar at. Vestibulum auctor congue pharetra. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nulla facilisi. Nunc dolor velit, interdum finibus bibendum vel, mattis a magna. Mauris mollis sapien ac mi aliquet varius. Proin nec est nibh. In hac habitasse platea dictumst. Nullam porta risus vitae lectus pellentesque interdum. Proin ac leo fermentum, volutpat odio ut, lacinia erat. Suspendisse potenti. Praesent vitae faucibus lectus. Sed tincidunt at ex id maximus. Morbi tristique ullamcorper velit, non cursus libero eleifend quis. Aliquam aliquam odio dui.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade" id="gettingStarted" role="tabpanel" aria-labelledby="gettingStarted-tab">
                        <div className="accordion" id="gettingStartedAccordion">
                            <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h3 className="accordion-header">
                                    <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-g1" aria-expanded="false" aria-controls="q-g1">What if I don't have any professional background?</button>
                                </h3>
                                <div className="accordion-collapse collapse" id="q-g1" data-bs-parent="#gettingStartedAccordion">
                                    <div className="accordion-body fs-sm pt-0">
                                        <p>Nunc duis id aenean gravida tincidunt eu, tempor ullamcorper. Viverra aliquam arcu, viverra et, cursus. Aliquet pretium cursus adipiscing gravida et consequat lobortis arcu velit. Nibh pharetra fermentum duis accumsan lectus non. Massa cursus molestie lorem scelerisque pellentesque. Nisi, enim, arcu purus gravida adipiscing euismod montes, duis egestas. Vehicula eu etiam quam tristique tincidunt suspendisse ut consequat.</p>
                                        <p>Ornare senectus fusce dignissim ut. Integer consequat in eu tortor, faucibus et lacinia posuere. Turpis sit viverra lorem suspendisse lacus aliquam auctor vulputate. Quis egestas aliquam nunc purus lacus, elit leo elit facilisi. Dignissim amet adipiscing massa integer.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h3 className="accordion-header">
                                    <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-g2" aria-expanded="false" aria-controls="q-g2">How is this different from other courses on the market?</button>
                                </h3>
                                <div className="accordion-collapse collapse" id="q-g2" data-bs-parent="#gettingStartedAccordion">
                                    <div className="accordion-body fs-sm pt-0">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce in facilisis nibh. Vestibulum ac porttitor sapien. Curabitur laoreet malesuada gravida. Phasellus vehicula vestibulum consequat. Curabitur feugiat eget sem vitae laoreet. Fusce porttitor finibus tellus, quis condimentum nibh. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vivamus vehicula malesuada magna at viverra. Fusce non est eget libero convallis fringilla suspendisse.</p>
                                        <p>Nunc dolor velit, interdum finibus bibendum vel, mattis a magna. Mauris mollis sapien ac mi aliquet varius. Proin nec est nibh. Dignissim amet adipiscing massa integer.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h3 className="accordion-header">
                                    <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-g3" aria-expanded="false" aria-controls="q-g3">How much time does it take to do my homework per week? What if I don't like it?</button>
                                </h3>
                                <div className="accordion-collapse collapse" id="q-g3" data-bs-parent="#gettingStartedAccordion">
                                    <div className="accordion-body fs-sm pt-0">
                                        <p>Suspendisse viverra volutpat eros. Curabitur in scelerisque lacus, quis fringilla sem. Nunc rutrum vel magna et ullamcorper. Sed consectetur augue vitae ligula consectetur, eu dapibus justo molestie. Phasellus sit amet metus magna. Sed tincidunt tempus felis vitae commodo. Etiam lobortis justo in elit pretium, sit amet aliquet tellus euismod. Curabitur in purus sed turpis aliquet pretium. Nunc ut magna tempus, iaculis sem id, vulputate ipsum. Etiam fermentum malesuada quam, in tempus purus pulvinar at. Vestibulum auctor congue pharetra. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nulla facilisi. Nunc dolor velit, interdum finibus bibendum vel, mattis a magna. Mauris mollis sapien ac mi aliquet varius. Proin nec est nibh. In hac habitasse platea dictumst. Nullam porta risus vitae lectus pellentesque interdum. Proin ac leo fermentum, volutpat odio ut, lacinia erat. Suspendisse potenti. Praesent vitae faucibus lectus. Sed tincidunt at ex id maximus. Morbi tristique ullamcorper velit, non cursus libero eleifend quis. Aliquam aliquam odio dui.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade" id="appDownload" role="tabpanel" aria-labelledby="appDownload-tab">
                        <div className="accordion" id="appDownloadAccordion">
                            <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h3 className="accordion-header">
                                    <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-app1" aria-expanded="false" aria-controls="q-app1">What if I don't have any professional background?</button>
                                </h3>
                                <div className="accordion-collapse collapse" id="q-app1" data-bs-parent="#appDownloadAccordion">
                                    <div className="accordion-body fs-sm pt-0">
                                        <p>Nunc duis id aenean gravida tincidunt eu, tempor ullamcorper. Viverra aliquam arcu, viverra et, cursus. Aliquet pretium cursus adipiscing gravida et consequat lobortis arcu velit. Nibh pharetra fermentum duis accumsan lectus non. Massa cursus molestie lorem scelerisque pellentesque. Nisi, enim, arcu purus gravida adipiscing euismod montes, duis egestas. Vehicula eu etiam quam tristique tincidunt suspendisse ut consequat.</p>
                                        <p>Ornare senectus fusce dignissim ut. Integer consequat in eu tortor, faucibus et lacinia posuere. Turpis sit viverra lorem suspendisse lacus aliquam auctor vulputate. Quis egestas aliquam nunc purus lacus, elit leo elit facilisi. Dignissim amet adipiscing massa integer.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h3 className="accordion-header">
                                    <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-app2" aria-expanded="false" aria-controls="q-app2">How is this different from other courses on the market?</button>
                                </h3>
                                <div className="accordion-collapse collapse" id="q-app2" data-bs-parent="#appDownloadAccordion">
                                    <div className="accordion-body fs-sm pt-0">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce in facilisis nibh. Vestibulum ac porttitor sapien. Curabitur laoreet malesuada gravida. Phasellus vehicula vestibulum consequat. Curabitur feugiat eget sem vitae laoreet. Fusce porttitor finibus tellus, quis condimentum nibh. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vivamus vehicula malesuada magna at viverra. Fusce non est eget libero convallis fringilla suspendisse.</p>
                                        <p>Nunc dolor velit, interdum finibus bibendum vel, mattis a magna. Mauris mollis sapien ac mi aliquet varius. Proin nec est nibh. Dignissim amet adipiscing massa integer.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h3 className="accordion-header">
                                    <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-app3" aria-expanded="false" aria-controls="q-app3">How much time does it take to do my homework per week? What if I don't like it?</button>
                                </h3>
                                <div className="accordion-collapse collapse" id="q-app3" data-bs-parent="#appDownloadAccordion">
                                    <div className="accordion-body fs-sm pt-0">
                                        <p>Suspendisse viverra volutpat eros. Curabitur in scelerisque lacus, quis fringilla sem. Nunc rutrum vel magna et ullamcorper. Sed consectetur augue vitae ligula consectetur, eu dapibus justo molestie. Phasellus sit amet metus magna. Sed tincidunt tempus felis vitae commodo. Etiam lobortis justo in elit pretium, sit amet aliquet tellus euismod. Curabitur in purus sed turpis aliquet pretium. Nunc ut magna tempus, iaculis sem id, vulputate ipsum. Etiam fermentum malesuada quam, in tempus purus pulvinar at. Vestibulum auctor congue pharetra. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nulla facilisi. Nunc dolor velit, interdum finibus bibendum vel, mattis a magna. Mauris mollis sapien ac mi aliquet varius. Proin nec est nibh. In hac habitasse platea dictumst. Nullam porta risus vitae lectus pellentesque interdum. Proin ac leo fermentum, volutpat odio ut, lacinia erat. Suspendisse potenti. Praesent vitae faucibus lectus. Sed tincidunt at ex id maximus. Morbi tristique ullamcorper velit, non cursus libero eleifend quis. Aliquam aliquam odio dui.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade" id="accountSetup" role="tabpanel" aria-labelledby="accountSetup-tab">
                        <div className="accordion" id="accountSetupAccordion">
                            <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h3 className="accordion-header">
                                    <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-account1" aria-expanded="false" aria-controls="q-account1">What if I don't have any professional background?</button>
                                </h3>
                                <div className="accordion-collapse collapse" id="q-account1" data-bs-parent="#accountSetupAccordion">
                                    <div className="accordion-body fs-sm pt-0">
                                        <p>Nunc duis id aenean gravida tincidunt eu, tempor ullamcorper. Viverra aliquam arcu, viverra et, cursus. Aliquet pretium cursus adipiscing gravida et consequat lobortis arcu velit. Nibh pharetra fermentum duis accumsan lectus non. Massa cursus molestie lorem scelerisque pellentesque. Nisi, enim, arcu purus gravida adipiscing euismod montes, duis egestas. Vehicula eu etiam quam tristique tincidunt suspendisse ut consequat.</p>
                                        <p>Ornare senectus fusce dignissim ut. Integer consequat in eu tortor, faucibus et lacinia posuere. Turpis sit viverra lorem suspendisse lacus aliquam auctor vulputate. Quis egestas aliquam nunc purus lacus, elit leo elit facilisi. Dignissim amet adipiscing massa integer.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h3 className="accordion-header">
                                    <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-account2" aria-expanded="false" aria-controls="q-account2">How is this different from other courses on the market?</button>
                                </h3>
                                <div className="accordion-collapse collapse" id="q-account2" data-bs-parent="#accountSetupAccordion">
                                    <div className="accordion-body fs-sm pt-0">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce in facilisis nibh. Vestibulum ac porttitor sapien. Curabitur laoreet malesuada gravida. Phasellus vehicula vestibulum consequat. Curabitur feugiat eget sem vitae laoreet. Fusce porttitor finibus tellus, quis condimentum nibh. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vivamus vehicula malesuada magna at viverra. Fusce non est eget libero convallis fringilla suspendisse.</p>
                                        <p>Nunc dolor velit, interdum finibus bibendum vel, mattis a magna. Mauris mollis sapien ac mi aliquet varius. Proin nec est nibh. Dignissim amet adipiscing massa integer.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h3 className="accordion-header">
                                    <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-account3" aria-expanded="false" aria-controls="q-account3">How much time does it take to do my homework per week? What if I don't like it?</button>
                                </h3>
                                <div className="accordion-collapse collapse" id="q-account3" data-bs-parent="#accountSetupAccordion">
                                    <div className="accordion-body fs-sm pt-0">
                                        <p>Suspendisse viverra volutpat eros. Curabitur in scelerisque lacus, quis fringilla sem. Nunc rutrum vel magna et ullamcorper. Sed consectetur augue vitae ligula consectetur, eu dapibus justo molestie. Phasellus sit amet metus magna. Sed tincidunt tempus felis vitae commodo. Etiam lobortis justo in elit pretium, sit amet aliquet tellus euismod. Curabitur in purus sed turpis aliquet pretium. Nunc ut magna tempus, iaculis sem id, vulputate ipsum. Etiam fermentum malesuada quam, in tempus purus pulvinar at. Vestibulum auctor congue pharetra. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nulla facilisi. Nunc dolor velit, interdum finibus bibendum vel, mattis a magna. Mauris mollis sapien ac mi aliquet varius. Proin nec est nibh. In hac habitasse platea dictumst. Nullam porta risus vitae lectus pellentesque interdum. Proin ac leo fermentum, volutpat odio ut, lacinia erat. Suspendisse potenti. Praesent vitae faucibus lectus. Sed tincidunt at ex id maximus. Morbi tristique ullamcorper velit, non cursus libero eleifend quis. Aliquam aliquam odio dui.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade" id="earnings" role="tabpanel" aria-labelledby="earnings-tab">
                        <div className="accordion" id="earningsAccordion">
                            <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h3 className="accordion-header">
                                    <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-earnings1" aria-expanded="false" aria-controls="q-earnings1">What if I don't have any professional background?</button>
                                </h3>
                                <div className="accordion-collapse collapse" id="q-earnings1" data-bs-parent="#earningsAccordion">
                                    <div className="accordion-body fs-sm pt-0">
                                        <p>Nunc duis id aenean gravida tincidunt eu, tempor ullamcorper. Viverra aliquam arcu, viverra et, cursus. Aliquet pretium cursus adipiscing gravida et consequat lobortis arcu velit. Nibh pharetra fermentum duis accumsan lectus non. Massa cursus molestie lorem scelerisque pellentesque. Nisi, enim, arcu purus gravida adipiscing euismod montes, duis egestas. Vehicula eu etiam quam tristique tincidunt suspendisse ut consequat.</p>
                                        <p>Ornare senectus fusce dignissim ut. Integer consequat in eu tortor, faucibus et lacinia posuere. Turpis sit viverra lorem suspendisse lacus aliquam auctor vulputate. Quis egestas aliquam nunc purus lacus, elit leo elit facilisi. Dignissim amet adipiscing massa integer.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h3 className="accordion-header">
                                    <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-earnings2" aria-expanded="false" aria-controls="q-earnings2">How is this different from other courses on the market?</button>
                                </h3>
                                <div className="accordion-collapse collapse" id="q-earnings2" data-bs-parent="#earningsAccordion">
                                    <div className="accordion-body fs-sm pt-0">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce in facilisis nibh. Vestibulum ac porttitor sapien. Curabitur laoreet malesuada gravida. Phasellus vehicula vestibulum consequat. Curabitur feugiat eget sem vitae laoreet. Fusce porttitor finibus tellus, quis condimentum nibh. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vivamus vehicula malesuada magna at viverra. Fusce non est eget libero convallis fringilla suspendisse.</p>
                                        <p>Nunc dolor velit, interdum finibus bibendum vel, mattis a magna. Mauris mollis sapien ac mi aliquet varius. Proin nec est nibh. Dignissim amet adipiscing massa integer.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h3 className="accordion-header">
                                    <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-earnings3" aria-expanded="false" aria-controls="q-earnings3">How much time does it take to do my homework per week? What if I don't like it?</button>
                                </h3>
                                <div className="accordion-collapse collapse" id="q-earnings3" data-bs-parent="#earningsAccordion">
                                    <div className="accordion-body fs-sm pt-0">
                                        <p>Suspendisse viverra volutpat eros. Curabitur in scelerisque lacus, quis fringilla sem. Nunc rutrum vel magna et ullamcorper. Sed consectetur augue vitae ligula consectetur, eu dapibus justo molestie. Phasellus sit amet metus magna. Sed tincidunt tempus felis vitae commodo. Etiam lobortis justo in elit pretium, sit amet aliquet tellus euismod. Curabitur in purus sed turpis aliquet pretium. Nunc ut magna tempus, iaculis sem id, vulputate ipsum. Etiam fermentum malesuada quam, in tempus purus pulvinar at. Vestibulum auctor congue pharetra. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nulla facilisi. Nunc dolor velit, interdum finibus bibendum vel, mattis a magna. Mauris mollis sapien ac mi aliquet varius. Proin nec est nibh. In hac habitasse platea dictumst. Nullam porta risus vitae lectus pellentesque interdum. Proin ac leo fermentum, volutpat odio ut, lacinia erat. Suspendisse potenti. Praesent vitae faucibus lectus. Sed tincidunt at ex id maximus. Morbi tristique ullamcorper velit, non cursus libero eleifend quis. Aliquam aliquam odio dui.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade" id="standings" role="tabpanel" aria-labelledby="standings-tab">
                        <div className="accordion" id="standingsAccordion">
                            <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h3 className="accordion-header">
                                    <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-standings1" aria-expanded="false" aria-controls="q-standings1">What if I don't have any professional background?</button>
                                </h3>
                                <div className="accordion-collapse collapse" id="q-standings1" data-bs-parent="#standingsAccordion">
                                    <div className="accordion-body fs-sm pt-0">
                                        <p>Nunc duis id aenean gravida tincidunt eu, tempor ullamcorper. Viverra aliquam arcu, viverra et, cursus. Aliquet pretium cursus adipiscing gravida et consequat lobortis arcu velit. Nibh pharetra fermentum duis accumsan lectus non. Massa cursus molestie lorem scelerisque pellentesque. Nisi, enim, arcu purus gravida adipiscing euismod montes, duis egestas. Vehicula eu etiam quam tristique tincidunt suspendisse ut consequat.</p>
                                        <p>Ornare senectus fusce dignissim ut. Integer consequat in eu tortor, faucibus et lacinia posuere. Turpis sit viverra lorem suspendisse lacus aliquam auctor vulputate. Quis egestas aliquam nunc purus lacus, elit leo elit facilisi. Dignissim amet adipiscing massa integer.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h3 className="accordion-header">
                                    <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-standings2" aria-expanded="false" aria-controls="q-standings2">How is this different from other courses on the market?</button>
                                </h3>
                                <div className="accordion-collapse collapse" id="q-standings2" data-bs-parent="#standingsAccordion">
                                    <div className="accordion-body fs-sm pt-0">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce in facilisis nibh. Vestibulum ac porttitor sapien. Curabitur laoreet malesuada gravida. Phasellus vehicula vestibulum consequat. Curabitur feugiat eget sem vitae laoreet. Fusce porttitor finibus tellus, quis condimentum nibh. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vivamus vehicula malesuada magna at viverra. Fusce non est eget libero convallis fringilla suspendisse.</p>
                                        <p>Nunc dolor velit, interdum finibus bibendum vel, mattis a magna. Mauris mollis sapien ac mi aliquet varius. Proin nec est nibh. Dignissim amet adipiscing massa integer.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                                <h3 className="accordion-header">
                                    <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-standings3" aria-expanded="false" aria-controls="q-standings3">How much time does it take to do my homework per week? What if I don't like it?</button>
                                </h3>
                                <div className="accordion-collapse collapse" id="q-standings3" data-bs-parent="#standingsAccordion">
                                    <div className="accordion-body fs-sm pt-0">
                                        <p>Suspendisse viverra volutpat eros. Curabitur in scelerisque lacus, quis fringilla sem. Nunc rutrum vel magna et ullamcorper. Sed consectetur augue vitae ligula consectetur, eu dapibus justo molestie. Phasellus sit amet metus magna. Sed tincidunt tempus felis vitae commodo. Etiam lobortis justo in elit pretium, sit amet aliquet tellus euismod. Curabitur in purus sed turpis aliquet pretium. Nunc ut magna tempus, iaculis sem id, vulputate ipsum. Etiam fermentum malesuada quam, in tempus purus pulvinar at. Vestibulum auctor congue pharetra. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nulla facilisi. Nunc dolor velit, interdum finibus bibendum vel, mattis a magna. Mauris mollis sapien ac mi aliquet varius. Proin nec est nibh. In hac habitasse platea dictumst. Nullam porta risus vitae lectus pellentesque interdum. Proin ac leo fermentum, volutpat odio ut, lacinia erat. Suspendisse potenti. Praesent vitae faucibus lectus. Sed tincidunt at ex id maximus. Morbi tristique ullamcorper velit, non cursus libero eleifend quis. Aliquam aliquam odio dui.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
