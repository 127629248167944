import './App.css';
// import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import Header from './Components/Layouts/Header/Header';
import Footer from './Components/Layouts/Footer/Footer';
import { BrowserRouter } from 'react-router-dom';
import Routing from './Components/Layouts/Routing/Routing';
import ScrollToTop, { ScrollToTopButton } from './Components/ScrollToTop/ScrollToTop';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
            <Header />
          </div>
          <div className="col-12 px-0">
            <Routing />
          </div>
          <div>
            <ScrollToTopButton />
          </div>
          <div className="col-12 px-0">
            <Footer />
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
