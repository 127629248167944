import axios from 'axios';
import { FETCH_AVAILABLE_TRIPS_REQUEST, FETCH_AVAILABLE_TRIPS_SUCCESS, FETCH_AVAILABLE_TRIPS_FAIL } from './ActionTypes';

export const fetchAvailableTripsRequest = () => {
  return {
    type: FETCH_AVAILABLE_TRIPS_REQUEST
  }
}

export const fetchAvailableTripsSuccess = (availableTrips) => {
  return {
    type: FETCH_AVAILABLE_TRIPS_SUCCESS,
    data: availableTrips,
  }
}

export const fetchAvailableTripsFail = (error) => {
  return {
    type: FETCH_AVAILABLE_TRIPS_FAIL,
    data: error,
  }
}

export const fetchAvailableTrips = (token) => {
  return async function (dispatch) {
    // console.log("available trips")
    dispatch(fetchAvailableTripsRequest());
    await axios({
      method: 'post',
      url: "https://logisticsapi.cargoarabia.com/api/v1/trips/available?lat=24.701628155120936&lon=46.74398784999999&dist=30000",
      data: {
        username: "admin@almady.com",
        password: "admin",
        grantType: "password",

        // username: "8142473183",
        // password: "vikrant1234",
        // grantType: "password"
      },
      headers: {
        'content-type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(res => {
        let availableTrips = res.data.results;
        // dispatch(fetchAvailableTripsSuccess(availableTrips));
        // console.log("Trip Data" + availableTrips);
      }).catch(error => {
        dispatch(fetchAvailableTripsFail(error))
      })
  }
}

export const generateToken = () => {
  return async function (dispatch) {
    dispatch(fetchAvailableTripsRequest());
    await axios({
      method: 'post',
      url: "https://logisticsapi.cargoarabia.com/api/v1/public/oauth/token",
      data: {
        username: "admin@almady.com",
        password: "admin",
        grantType: "password",

        // username: "8142473183",
        // password: "vikrant1234",
        // grantType: "password"
      },
      headers: {
        'content-type': 'application/json; charset=utf-8',
      }
    })
      .then(async res => {
        let token = res.data.accessToken;

        await axios({
          method: 'get',
          url: "https://logisticsapi.cargoarabia.com/api/v1/trips/available?lat=24.701628155120936&lon=46.74398784999999&dist=30000",
          headers: {
            'content-type': 'application/json; charset=utf-8',
            'Authorization': 'Bearer ' + token
          }
        })
          .then(res => {
            let availableTrips = res.data.results;
            dispatch(fetchAvailableTripsSuccess(availableTrips));
            // console.log("Trip Data" + availableTrips);
          }).catch(error => {
            dispatch(fetchAvailableTripsFail(error))
          })

        // fetchAvailableTrips(token);
        // dispatch(fetchAvailableTripsSuccess(token));
        // console.log("Generate Token Console" + " " + token);
      }).catch(error => {

      })
  }
}