import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <footer className="footer pt-5 pb-4 pb-lg-5" style={{backgroundImage: 'url(/img/Footer/footer.jpg)'}}>
            <div className="container text-center pt-lg-3">
                <div className="navbar-brand justify-content-center text-dark mb-2 mb-lg-4">
                    <Link to="homepage">
                        <img src="Logo.png" className="me-2" width="150" alt="Logo" />
                    </Link>
                </div>
                <ul className="nav justify-content-center pt-3 pb-4 pb-lg-5">
                    <li className="nav-item"><Link to="homepage" className="nav-link">Home</Link></li>
                    <li className="nav-item"><Link to="who-we-are" className="nav-link">Who we are</Link></li>
                    <li className="nav-item"><Link to="locations" className="nav-link">Locations</Link></li>
                    <li className="nav-item"><Link to="faqs" className="nav-link">FAQ's</Link></li>
                    <li className="nav-item"><Link to="site-terms" className="nav-link">Site terms</Link></li>
                    <li className="nav-item"><Link to="privacy-notice" className="nav-link">Privacy notice</Link></li>
                </ul>
                <div className="d-flex flex-column flex-sm-row justify-content-center">
                    <a href="https://apps.apple.com/in/app/cargo-arabia-courier-%D8%A7%D9%84%D8%B1%D8%A8%D8%A7%D9%86/id6443718911" target="_blank" className="btn btn-dark btn-lg px-3 py-2 me-sm-4 mb-3" rel="noreferrer">
                        <img src="assets/icons/appstore-light.svg" className="light-mode-img" width="124" alt="App Store" />
                        <img src="assets/icons/appstore-dark.svg" className="dark-mode-img" width="124" alt="App Store" />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.cargoarabia.courier" target="_blank" className="btn btn-dark btn-lg px-3 py-2 mb-3" rel="noreferrer">
                        <img src="assets/icons/googleplay-light.svg" className="light-mode-img" width="139" alt="Google Play" />
                        <img src="assets/icons/googleplay-dark.svg" className="dark-mode-img" width="139" alt="Google Play" />
                    </a>
                </div>
                <div className="d-flex justify-content-center pt-4 mt-lg-3">
                    <a href="https://www.facebook.com/CargoArabia/" target="_blank" className="btn btn-icon btn-secondary btn-facebook mx-2" rel="noreferrer">
                        <i className="bx bxl-facebook"></i>
                    </a>
                    <a href="https://www.instagram.com/cargoarabia/" target="_blank" className="btn btn-icon btn-secondary btn-instagram mx-2" rel="noreferrer">
                        <i className="bx bxl-instagram"></i>
                    </a>
                    <a href="https://twitter.com/cargoarabia/" target="_blank" className="btn btn-icon btn-secondary btn-twitter mx-2" rel="noreferrer">
                        <i className="bx bxl-twitter"></i>
                    </a>
                    <a href="https://www.linkedin.com/company/madycorp/" target="_blank" className="btn btn-icon btn-secondary btn-linkedin mx-2" rel="noreferrer">
                        <i className="bx bxl-linkedin"></i>
                    </a>
                </div>
                <p className="nav d-block fs-sm text-center mt-lg-4 mb-0">
                    <span className="opacity-80">2022 &copy; All rights reserved. </span>
                    <a className="nav-link d-inline-block p-0" href="https://cargoarabia.com/" target="_blank" rel="noreferrer">
                        CargoArabia</a>
                </p>
            </div>
        </footer>
    )
}
